<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">

            <b-card
              no-body
              class="mb-0"
            >
              <div class="mr-2 ml-2 mt-1 mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-1"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                class="position-relative"
                primary-key="id"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há status cadastrados."
                :items="data"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="edit"
              >
                <template #cell(field_id)="field">
                  <b-media vertical-align="center">
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      #{{ field.item.id }}
                    </b-link>
                  </b-media>
                </template>

                <template #cell(field_name)="field">
                  <span class="font-weight-bold text-nowrap">
                    <b-badge
                      pill
                      class="badgeStyle d-inline-flex align-items-center justify-content-center text-capitalize sizeMin"
                      :style="{'background-color': field.item.color}"
                    >
                      {{ field.item.name }}
                    </b-badge>
                  </span>
                </template>

                <template #cell(field_phase)="field">
                  <span class="font-weight-bold text-nowrap">
                    {{ field.item.phase_set.name }}
                  </span>
                </template>

                <template #cell(field_actions)="field">
                  <div>
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-info"
                      size="md"
                      @click="showConfirmOrCancelDelete(field.item)"
                    >
                      <svg-icon
                        type="mdi"
                        size="18"
                        :path="mdiTrashCanOutline"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalColorName"
        ref="modalColorName"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(save)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Cadastro de Status
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="value.name"
                    name="status"
                    placeholder="Nome"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <phase-select
                id="phaseSelect"
                v-model="value.phase_set"
                :readonly="false"
                :required-field="true"
                @ready="syncLoad.ready('phase')"
              />
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col>
              <b-form-group
                label="Cor"
                label-for="color"
              >
                <color-picker
                  v-model="colorSelector"
                  class="mt-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
            align-v="center"
          >
            <b-col>
              <validation-provider
                #default="validationContext"
                name="color"
                :rules="{
                  required: true,
                  regex:/^#([0-9abcdefABCDEF]{6})$/
                }"
              >
                <b-form-input
                  id="color"
                  v-model="value.color"
                  name="color"
                  placeholder="#ffffff"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-badge
                pill
                class="badgeStyle d-inline-flex align-items-center justify-content-center text-capitalize"
                :style="{'background-color': value.color}"
              >
                {{ value.name | truncate(12, '...') }}
              </b-badge>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col>
              <b-form-group
                label="É o status inicial da licitação?"
                label-for="initial"
              >
                <b-form-checkbox
                  id="initial"
                  v-model="value.initial"
                  class="my-50"
                  switch
                  button-variant="secondary"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
    <div>
      <b-button
        id="addItemBtn"
        v-b-tooltip.hover
        v-b-modal.idModalColorName
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :disabled="!hasPermissionToWrite"
        title="Adicionar status"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BLink,
  BModal,
  BBadge,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiCogOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
  confirmed,
} from '@validations'

import {
  Slider,
} from 'vue-color'

import vSelect from 'vue-select'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'
import PhaseSelect from '@/components/bidding/PhaseSelect.vue'

export default {
  components: {
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BLink,
    BModal,
    BBadge,
    SpinLoader,
    SvgIcon,
    vSelect,
    ColorPicker: Slider,
    ValidationProvider,
    ValidationObserver,
    PhaseSelect,
  },
  directives: {
    VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      {
        key: 'field_id',
        label: 'ID',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_name',
        label: 'Nome',
      },
      {
        key: 'field_phase',
        label: 'Fase',
      },
      {
        key: 'field_actions',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    const data = []

    const value = {
      name: '',
      color: '#005C96',
      phase: '',
      phase_set: {
        name: '',
        color: '',
      },
      initial: false,
    }

    const colorSelector = { hex: '#005C96' }

    const isLoaded = false
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('status')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const isAdd = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.value)

    return {
      required,
      confirmed,
      refFormObserver,
      getValidationState,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      data,
      value,
      colorSelector,
      isLoaded,
      syncLoad,
      isAdd,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
    colorSelector: function updateColor(value) {
      if (typeof value === 'object') {
        if ('hex' in value) {
          this.value.color = value.hex
        }
      }
    },
    'value.color': function updateValueColor(obj) {
      this.colorSelector = obj
    },
    'value.phase_set': function updatePhase() {
      this.$refs.refFormObserver.reset()
      this.$refs.refFormObserver.validate()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .bidding
        .status
        .fetchAllStatus({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
        })
        .then(response => {
          this.totalItems = response.data.count
          this.data = response.data.results

          this.syncLoad.ready('status')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados de statuss.',
              text: 'Não foi possível ler os dados das statuss, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('status')
        })
    },
    deleteClient(item) {
      syslic
        .bidding
        .status
        .deleteStatus(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Status removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(error => {
          let textError = 'Não foi possível remover este status, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este status é referenciado em alguma licitação, por este motivo não será possível remove-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover status.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover o status.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteClient(item)
          }
        })
    },
    add(val) {
      syslic
        .bidding
        .status
        .addStatus(val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Status adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar este status, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('duplicate key value violates unique constraint')) {
              textError = 'Já existe um status com este nome, não é possivel adicionar um status com nome repetido.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar status.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    update(id, val) {
      syslic
        .bidding
        .status
        .updateStatus(id, val)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Status atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar este status, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('duplicate key value violates unique constraint')) {
              textError = 'Já existe um status com este nome, não é possivel atualizar um status com nome repetido.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar status.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    edit(item) {
      this.value = JSON.parse(JSON.stringify(item))
      this.colorSelector = item.color
      this.isAdd = false
      this.$refs.modalColorName.show()
    },
    save() {
      this.value.phase = this.value.phase_set.id

      if (this.isAdd) {
        this.add(this.value)
      } else {
        this.update(this.value.id, this.value)
      }

      this.isAdd = true
    },
    cancel() {
      this.value = {
        name: '',
        color: '#005C96',
        phase: '',
        phase_set: '',
        initial: false,
      }

      this.isAdd = true
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiCogOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.smallField {
  width: 5px !important;
}

.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";

.card-title {
  color: $primary
}

#idModalColorName .vc-slider {
  width: 100% !important;
}

#idModalColorName .modal-footer {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: end !important;
  background-color: #fff !important;

  .btn{
    min-width: 107px;
  }
}

#addItemBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

.sizeMin{
  min-width: 110px
}

.badgeStyle {
  height: 2.2rem;
  min-width: 10rem;
  font-size: 1rem;
}
</style>
